import UserEdit from './UserEdit'



var userCats = [];

var globalModal;


kv.privategateway = 'kiloverse.mypinata.cloud';
kv.publicgateway = 'cloudflare-ipfs.com';

kv.gateway = function(isPrivate) {
  return isPrivate ? kv.privategateway : kv.publicgateway;
}

kv.masterEditor = function() {

  const el = document.createElement('div');

  for(const data of kv.items){

    console.log('mars:' + JSON.stringify(data));

    const tr = document.createElement('div');
    tr.style = 'float:left;width:45%;margin-right:2%';
    tr.className = 'editrow';
    el.appendChild(tr);

    const img = document.createElement('img');
    img.style = 'width:100%;';
    img.src = data.url
    tr.appendChild(img);


    const txt = document.createElement('label');
    txt.innerHTML = data.prompt || '';
    txt.innerHTML += '<br>' + data.id;
    tr.appendChild(txt);

    const del = document.createElement('button');
    del.innerHTML = 'DEL';
    del.className = 'btn square';
    del.addEventListener('click', function(){
      kv.kilodata = kv.kilodata.filter(t=>t.id != data.id);
      kv.syncEverything();
      kv.saveKilo();
      el.remove();
      kv.masterEditor();
    })
    tr.appendChild(del);

  }

  kv.quickModal(el);

}

kv.quickModal = function(el, noclose) {
  launchModal();
  el.classList.add('container');
  kv.showHide(closeModalButton,  !noclose);
  modalContainer.appendChild(el);
}


var launchModal = function(id) {

  document.querySelectorAll('.container').forEach((el, i) => {
    el.parentNode.removeChild(el);

  });

  if(!globalModal){

    globalModal = new bootstrap.Modal(globalModalContainer, {backdrop: false, keyboard: false});
    window.globalModal = globalModal;



    globalModalContainer.addEventListener('show.bs.modal', ()=>{kv.isModal = true;});
    globalModalContainer.addEventListener('hidden.bs.modal', ()=>{
      modalFooter.innerHTML = '';
      setTimeout(function(){
        kv.isModal = false;
      }, 200)
    });

  }

  modalFooter.innerHTML = '';

  //if(id)document.getElementById(id+'Container').style.display = 'block';
  globalModal._config.focus = false;
  globalModal.show();
  window.modal = globalModal;

}



function loadPlotSettings(xid) {

  kv.api('kilo/'+xid, 'GET', {}, r => {

    document.getElementById('kiloTitle').value = r.title || '';
    document.getElementById('kiloTags').value = r.tags || '';
    document.getElementById('kiloDescription').value = r.description || '';


    reloadUsers(r.users || []);


    kv.kilo.items.forEach((item, i) => {
      kiloSettingsItems.innerHTML = '';
      var cell = makeGridCell(item, (item)=>{
        kv.launchItem(item)
        kiloSettingsItems.append(cell);
      });

    });

  });

}

kv.editPerson = function(user) {

  if(!kv.userBeingViewed){
    alert('cant find user');
    return;
  }
  kv.launchUserSettings(user, false);
}

function makeBot(userId, name) {
  kv.api('user', 'POST', {id:userId, isBot:true}, function(){
    alert('made ' + name + ' into a bot.');
  });
}




function selectAvatarType() {

}


function reloadUsers(users) {
  userStuff.innerHTML = '';
  users.forEach((user, i) => {
    const ur = document.createElement('div');
    ur.style="float:left;width:100px;background-color:#333;border-radius:12px;margin-right:5px;margin-bottom:5px;";
    ur.innerHTML =  user.name;// + '(' + user.isSelfie?'selfie':'nonselfie' + ')';
     userStuff.appendChild(ur);
     let del = document.createElement('div');
     del.className = "playlist-delete";
     del.onpointerup = function(){deleteUser(user.id);};
     del.style = "background-image:url('https://cdn.kiloverse.io/trash.png');background-size:contain;width:30px;height:30px;float:left;margin-top:0px;margin-right:4px;cursor:pointer;";
     ur.prepend(del);

  });
}

kv.loadVoices = function(cb) {
  kv.api('voices', 'GET', {}, function(r){
    var allvoices  = r.voices;
    kv.voices = [];
    allvoices.forEach((v, i) => {
      if(v.languageCodes[0] == 'en-US' && v.name.includes('Wavenet')){
        kv.voices.push(v);

      }
    });

    kv.voices.sort(function(a, b){
      if(a.name < b.name) { return -1; }
      if(a.name > b.name) { return 1; }
      return 0;
    });

    if(cb)cb();

  });
}


var updateItem = function(formData) {

  kv.api('add', 'POST', formData, r=>{
    debugger;
    alert(JSON.stringify(r));
  });

}

var makeAvatarForm = function(item) {

  var div = document.createElement('div');
  var l1 = document.createElement('div');
  l1.innerHTML = 'scale';
  div.appendChild(l1);
  var scaleinput = document.createElement('input');
  scaleinput.id = 'itemScale';
  div.appendChild(scaleinput);

  var l2 = document.createElement('div');
  l2.innerHTML = 'y position';
  div.appendChild(l2);
  var yinput = document.createElement('input');
  yinput.id = 'itemY';
  div.appendChild(yinput);
  if(item)scaleinput.value = item.scale || 1;
  if(item)yinput.value = item.y  || 0;
  return div;
}

var getFormDataAvatar = function() {
  return {scale:parseFloat(itemScale.value), y:parseFloat(itemY.value)};
}

kv.burnItem = function(item, quantity, cb) {

  if (confirm('Burning is irreversable - are you sure?') == true) {

    kv.burn(item, quantity, ()=>{
      if(cb)cb();
    });


  } else {

  }



  //alert('this is irreversable are you sure?');


  //  kv.api('deleteitem', 'POST', {itemId:item.id, userId:kv.user.id}, r=> {
    //  kv.launchMyItems();
  //  });

}

var makeRow = function(){
  const div = document.createElement('div');
  div.className="row"
  return div;
}

var makeCol = function(w){
  const div = document.createElement('div');
  div.className= 'col' + (w ? ('-'+w) : '');
  return div;
}

var makeLabel = function(title, myclass, callback) {
  var el = document.createElement('div');
  el.className = '' + myclass;
  el.innerHTML = title;
  //el.id = id;
  el.addEventListener('pointerup', function(){
    callback();
  });
  return el;
}

var makeButton = function(title, id, cls, callback) {
  var el = document.createElement('button');


  el.className = cls;
  el.innerHTML = title;
  el.id = id;
  el.addEventListener('pointerup', function(){
    callback();
  });
  return el;
}


var loadItem = function(item) {


    kv.lookupSwaps(item, (swaps)=>{
      //if(swapId){

        item.swaps = swaps;
        var f = makeAvatarForm(item);
        itemActionButtons.innerHTML = '';

        itemInfo.innerHTML = '';

        var tok = document.createElement('div');
        tok.innerHTML = (item.tokenId) ? ('KILO' + item.tokenId) : 'Processing...';
        itemInfo.appendChild(tok);
        var title = document.createElement('div');
        title.innerHTML = item.name;//oops
        itemInfo.appendChild(title);
        var description = document.createElement('div');
        description.innerHTML = item.description || '';
        itemInfo.appendChild(description);



        itemFooter.innerHTML = '';



        itemFooter.appendChild(makeButton('Exhibit', 'itemButtonDisplay', 'btn round btn-green-moon w-100 mt-3', ()=>{

          if (confirm('Display in current plot - ' + kv.kilo.tokenId + '?') == true) {
              kv.addToKilo(item);
          }

        }));


        if(item.type == 'instapost' || item.type == 'youtube'){

          itemActionButtons.appendChild(makeButton('Delete', 'itemButtonDelete', 'btn btn-secondary square',()=>{

            kv.api('deleteitem', 'POST', {userId:user.id, itemId:item.id}, function(){

              console.log('deleted item ' + item.id);

               kv.launchUserProfile(user);


            });

          }));

        }


        if(item.balance>0){
          itemActionButtons.appendChild(makeButton('List For Sale', 'itemButtonSwap','btn btn-secondary square', ()=>{

            kv.showHide(swapForm, true);


            swapForm.appendChild(makeButton('List For Sale', 'itemButtonSwapSubmit','btn btn-secondary square', ()=>{

              var xtz = parseInt(swapXTZ.value* 1000000);
              if(xtz == 0 || xtz.length == 0){
                alert('Price cannot be zero');
                return;
              }
              var efs = parseInt(swapEFS.value);
              if(efs == 0 || efs.length == 0){
                alert('Price cannot be zero');
                return ;
              }
              item.xtz  = xtz;
              item.editionsForSale = efs;


              alert('swaping ' + efs + ' for ' + xtz + ' on ' + item.contract);
              kv.swap(item, function(){
                alert('listing suceeded');
              });

            }));

          }));
          itemActionButtons.appendChild(makeButton('Burn', 'itemButtonDelete', 'btn btn-secondary square', ()=>{
            kv.showHide(burnForm, true);
            maxBurn.innerHTML = item.balance;

            burnButtonCol.appendChild(makeButton('Burn', 'itemButtonSwapSubmit','btn btn-secondary square red', ()=>{
                kv.burnItem(item, burnQuantity.value, ()=>{

                });
            }));
          }));


        }





        itemListings.innerHTML = '';
        var hasSwap = false;

        if(item.swaps){
        item.swaps.forEach((swap, i) => {
          const buytext = swap.value.kilo_amount + ((swap.value.kilo_amount > 1) ?  ' copies' : ' copy');
          var row = itemListings.appendChild(makeRow());
          row.className = 'mb-3 row';

          if(swap.value.kilo_amount > 0)hasSwap = true;
          if(swap.value.kilo_amount == 0)row.className += ' d-none';

          var col1 = row.appendChild(makeCol(3));
          var col2 = row.appendChild(makeCol(5));
          var col3 = row.appendChild(makeCol(4));
          col1.appendChild(makeLabel(buytext, 'itemButtonCollectLabel', ()=>{}));
          var swapObject = {swapId:swap.key, ...swap.value};

          col2.appendChild(makeButton('Collect for ' + Math.round(swap.value.xtz_per_kilo /1000000 * 100)/100.0+'ꜩ', 'itemButtonCollect', 'btn btn-secondary square', ()=>{kv.collect(swapObject)}));
          console.log('issuer' + swap.value.issuer);
          if(swap.value.issuer == kv.walletAddress){

            col3.appendChild(makeButton('Cancel Listing', 'itemButtonCancel', 'btn btn-secondary square', ()=>{

                if (confirm('Cancel listing?') == true) {
                  kv.cancel(swapObject);
                }

            }));
          }
        });
        }

        if(!hasSwap){
          itemListings.innerHTML = 'not currently for sale.';
        }


        //alert(kv.pathFromIPFS(item.artifactUri));

        itemPreviewThumb.style.backgroundImage = 'url('+kv.pathFromIPFS(item.artifactUri)+')';
        itemPreviewThumb.style.backgroundSize = 'contain';

      //}
    });

}

  var deleteFromPlaylist = function(itemtodelete) {
      kilo.playlists.forEach(function(playlist){
         playlist.items =  playlist.items.filter((el, idx)=>{
            return itemtodelete != el;
          });
       //reYeah );
      });
  }



var deleteUser = function(id) {
    kv.kilo.users = kv.kilo.users.filter(user => user.id != id);
    reloadUsers(kv.kilo.users);
}



  var deleteFromItemLibrary = function(id) {


    kv.api('deleteitem', 'POST', {userId:user.id, itemId:id}, function(){

      //kv.userItems = kv.userItems.filter(function(i){return i.id != id});
      kv.launchUserSettings(user, false);

    });

}

kv.removeFromKilo = function(ditem) {

  console.log('sweetwater before:' + kv.kilo.items.length);
  kv.kilo.items = kv.kilo.items.filter(it => ((it.tokenId != ditem.tokenId) || (it.contract != ditem.contract)));
  console.log('sweetwater after:' + kv.kilo.items.length);
  kv.saveAndRenderKilo();

}

var saveKiloSettings = function(id) {
  var stuff = {xid:id};
  stuff.title =  document.getElementById('kiloTitle').value;
  stuff.description =  document.getElementById('kiloDescription').value;
  stuff.tags =  document.getElementById('kiloTags').value;
  stuff.users = kv.kilo.users;
  kv.api('kilo', 'POST', stuff, r => {


    kv.transferPexels(kv.kilo.backgroundUri, id);

    //updateLayout();
    //alert('save and render... ' + JSON.stringify(r));
  });

}

function secondsSinceEpoch() {
  const now = new Date()
  const utcMilllisecondsSinceEpoch = now.getTime() + (now.getTimezoneOffset() * 60 * 1000)
  const utcSecondsSinceEpoch = Math.round(utcMilllisecondsSinceEpoch / 1000)
  return utcSecondsSinceEpoch;
}

kv.addToKilo = function(item) {



  if(!kv.kilo)return;

  kv.kilo.items.filter((i)=>item.artifactUri == i.artifactUri || (item.id && item.id == i.id));

  item.created = secondsSinceEpoch;
  kv.kilo.items.push(item);

  //console.log('added fucking item ' + item.name || 'unknown name');

  kv.saveAndRenderKilo();

}


var reloadLibrary = function() {

    var catitems = items.filter((i)=>{return i.type==activecat});

    while (libraryContainer.firstChild) {
        libraryContainer.removeChild(libraryContainer.firstChild);
    }

    var row;
    for (let i = 0; i < catitems.length; i++) {
      if(i % 4 == 0){
      //row = document.createElement('div');
        //row.className = 'row';
        //library.appendChild(row);
      }

      var item = catitems[i];
      let cell  = document.createElement('div');
      cell.className = "library-cell";
      cell.style = "height:50px;margin-bottom:5px;"


      let img = document.createElement('div');
      img.className = "library-thumb";
      img.id='add'+item.id;
      img.style = "pointer-events:'auto';background-size:cover;background-position:center;background-repeat:no-repeat;float:left;width:50px;height:50px;background-size:fill;background-image:url(https://cdn.kiloverse.io/"+item.id+".png);";
      cell.appendChild(img);


      var edit = document.createElement('div');
      edit.className = "item-edit";
      edit.id = catitems[i].id;
      edit.onpointerup = function(){editItem(this.id);}
      edit.innerHTML = 'EDIT';
      edit.style = "cursor:pointer;font-size:20px;text-align:center;color:white;border-radius:15px;width:60px;height:30px;background-color:black;float:right;";

       cell.appendChild(edit);


       /*
      var del = document.createElement('div');
      del.className = "item-delete";
      del.id = catitems[i].id;
      del.onpointerup = function(){deleteFromItemLibrary(this.id);}
      del.style = "cursor:pointer;background-image:url('https://api.kiloverse.io/trash.png');background-size:contain;font-width:bolder;text-align:center;color:white;width:30px;height:30px;border-radius:15px;float:right;";
      cell.appendChild(del);
      */

      let text = document.createElement('p');
      text.innerHTML = htmlInfo(item);
      text.style = 'margin-left:60px;font-size:10px;overflow:hidden;'
      cell.appendChild(text);

      libraryContainer.appendChild(cell);

    }


}



kv.pathFromIPFS = function(ipfs) {

  if(!ipfs)return '';
   if(ipfs.includes('ipfs')){
      var cid = ipfs.replace('ipfs://','')
      return 'https://'+kv.gateway()+'/ipfs/' + cid;
  }else{
    return ipfs;
  }

}


kv.loadProperties = function() {


  if(!kv.propertyContractAddress)alert('no conract address');
  var net = kv.isDev ? 'ithacanet':'mainnet';
  kv.api('tokens', 'POST', {network:net, contract:kv.propertyContractAddress}, (r)=>{
    r.forEach((item, i) => {

    });
  });


}



function makeTableRow(item) {

  let row  = makeRow();
  let c1 = makeCol(2);
  c1.innerHTML = kv.addressFromIndex(item.tokenId);

  let c2 = makeCol(6);
  c2.innerHTML = item.title || '';


  let c3 = makeCol(2);
  c3.innerHTML = '<button onpointerup="kv.launchPlotSettings('+item.tokenId+')" class="btn square">EDIT</button>';

  let c4 = makeCol(2);
  c4.innerHTML = '<button onpointerup="kv.visit('+item.tokenId+')" class="btn square">VISIT</button>';

  row.append(c1);
  row.append(c2);
  row.append(c3);
  row.append(c4);
  return row;
}

kv.getThumbFromItem = function(item) {
  return item.thumbnailUri;
}

function makeGridCell(item, cb) {

  let cell  = document.createElement('div');

  //console.log('grid cell for ' + item.type);

  cell.style.fontFamily =  'Source Code Pro, monospace';
  cell.style.fontSize = '11px';
  cell.onpointerup = (e)=>{

    //prevent subviews from triggering...
    if(!e.target.classList.contains('playlist-delete'))cb(item);
  };
  cell.className = "playlist-cell";

  let img = document.createElement('div');
  img.className = "playlist-thumb";
  img.id = 'add-'+item.id;

/*
  var ti = item.displayUri || item.artifactUri;
  if(item.symbol == 'KILO' || item.type == 'youtube'){
    ti = item.thumbnailUri;
  }
  */



  var bi = kv.getThumbFromItem(item);
  // kv.pathFromIPFS(ti);


  img.style = 'background-image:url('+ bi +');';

  let title = document.createElement('div');
  title.className = 'playlist-title';

  var mime = (item.formats && (item.formats.length > 0)) ? item.formats[0].mimeType : '';
  if(item.symbol) {
    title.innerHTML = item.symbol+item.tokenId + '<br>' + mime;
  }else{
    title.innerHTML = item.type;
  }


  const name = document.createElement('div');
  name.className = 'playlist-name';
  name.innerHTML = item.name || ''

  cell.appendChild(img);
  cell.appendChild(title);
  cell.appendChild(name);

//if(name.innerHTML == 'Fireflies'){
  //  debugger;
//  }

  //console.log(name.innerHTML + ': ' + bi);
  //console.log('fuck ' + item.name + ' ' + item.url);
  return cell;

}


kv.submitLink = function() {

  if(linkText.value.length == 0){
    alert('enter a link!');
    return;
  }
  kv.api('add', 'POST', {userId:kv.user.id, url:linkText.value}, (r)=>{
    kv.loadItems(kv.user);
  });

}

kv.launchItemPicker = function() {

  launchModal('itemPicker');
  itemPickerStuff.innerHTML = '';

  kv.kilo.items.forEach((item, i) => {
    item.idx = i;
  });

  var sorted = kv.kilo.items.sort(function(a, b) {

    if(a.created && !b.created){
      return -1;
    }else if(b.created && !a.created){
      return 1;
    }else if(a.created > b.created){
      return -1;
    }else{
      return 0;
    }


  });

  //var sorted = kilo.items.sort();


  console.log('sorted so first is ' + sorted[0].created + ' and last is ' + sorted[sorted.length-1].created);


  sorted.forEach((item, i) => {

    var cell = makeGridCell(item, (i)=>{

      var p =  {tokenId:kv.kilo.tokenId};
      if(item.type == 'youtube'){
         p.currentVid = item;

      }else{

        p.currentArt = item;

      }


      kv.api('kilo', 'POST', p, ()=>{});
      globalModal.hide();
    });

    if(kv.isEd()){

      var del = document.createElement('button');
      del.className = 'btn playlist-delete';
      del.style.pointerEvents = 'auto';
      del.innerHTML = '<i class="user-select-none fa-solid fa-trash"></i>';
      del.style.position = 'absolute';
      del.style.bottom = '0px';
      del.style.left = '0px';

      del.style.backgroundColor = '#111';
      del.addEventListener('pointerup', function(event){
        if (confirm("Remove from this gallery?") == true) {
          kv.removeFromKilo(item);
        }
      });

      cell.appendChild(del);
    }

    itemPickerStuff.append(cell);

  });

}



kv.myPlots = [];

kv.loadPublicItems = function(cb) {

  kv.api('items', 'POST', {}, (r) =>{
    cb(r);
  });

}

kv.isUserCreator = function(user, item) {

  var creator = '';

  if(item.creators && item.creators[0]){
    creator = item.creators[0];
  }

  return creator == user.walletAddress;

}

//break this baby up
kv.loadItems = function(anyUser, cb) {

  //alert('load items from ' + anyUser.name + ' ' + anyUser.walletAddress);
//if(!kv.walletAddress)alert('no wallet address');


 var obj = {network: kv.isDev ? 'ithacanet' : 'mainnet'};

 if(anyUser && anyUser.walletAddress)obj['address'] = anyUser.walletAddress;

  kv.api('tokens', 'POST', obj, (r)=>{

    //console.log(JSON.stringify(r));

    var items = [];
    var minted = [];
    var collected = [];
    var plots = [];
    var links = [];


    const reversed = r.reverse();


    console.log('found ' + reversed.length + ' items');
    reversed.forEach((item, i) => {


      var item = {id:item.id, balance:item.balance, tokenId:item.token.tokenId,  ...item.token.metadata, contract:item.token.contract.address};
      items.push(item);

      //console.log(item.name + ': ' + creator);

      if(item.contract == kv.propertyContractAddress){

        plots.push(item);

      } else {


        if(kv.isUserCreator(anyUser, item)){

          minted.push(item);
        }else{
          collected.push(item);
        }
      }

    });

    kv.myPlots = plots;


    kv.api('items', 'POST', {userId:anyUser.id}, (l) =>{
    //  debugger;
    var dbitems = [];
    stuffLinks.innerHTML = '';
      l.forEach((item, i) => {
        dbitems.push(item);
        if(item.type == 'youtube' || item.type == 'instapost'){

          var cell = makeGridCell(item, (item)=>{
            kv.launchItem(item)

          });
          stuffLinks.appendChild(cell);
        }


      });

      //kv.userItems = dbitems;
      if(cb)cb();
    });


    if(kv.headless || !anyUser)return;

    var sales = [];

    items.forEach((sitem, i) => {

      if(kv.swaps)kv.swaps.forEach((s, i) => {
        //console.log('swap:' + JSON.stringify(s));
        if(s.tokenId && sitem.tokenId && (s.tokenId == sitem.tokenId)){
            sales.push(sitem);
        }
      });

    });

    //debugger;


  var sections = [{title:'Minted', items:minted}, {title:'Collected', items:collected}, {title:'Plots', items:plots},{title:'For Sale', items:sales}];



  //if(!anyUser.items)anyUser.items = [];
  sections.forEach((section, s) => {

    var tgt;
    if(s==0)tgt = 'stuffMints';
    if(s==1)tgt = 'stuffCollection';
    if(s==2)tgt = 'stuffPlots';
    if(s==3)tgt = 'stuffForSale';
  //  if(s==4)tgt = ';

    var targetDiv = document.getElementById(tgt);
    //console.log('s is ' + s + 'targetdiv is ' + targetDiv);

    targetDiv.innerHTML = '';





      for (let i = 0; i < section.items.length; i++) {


      let item = section.items[i];

      var cell;

      if(s != 2)cell = makeGridCell(item, (item)=>{
         kv.launchItem(item)
      });

      if(s==2)cell = makeTableRow(item);


        targetDiv.appendChild(cell);


      }

      anyUser.items = r;

    });

  });

}


/*
document.querySelectorAll('.uploadAssets').forEach((item, i) => {
  item.addEventListener('pointerup', function(){
    activecat = item.id.replace('upload','');
    kv.launchArt(activecat);
  });

});

var randomId = '';
randomSaveButton.addEventListener('pointerup', function(){
  kv.api('savehen/'+randomId+'/'+kilo.address, 'GET', {}, function(r){
    kilo = r.kilo;
  });
});

randomArtButton.addEventListener('pointerup', function(){
  randomId = parseInt(Math.random()*777777);
  kv.api('hen/'+ randomId, 'GET', {},  function(r){
    randomPreview.innerHTML = '<img style="width:100%;" class="previewImage" src="'+r.metadata.displayUri.replace('ipfs://', 'https://'+kv.gateway()+'/ipfs/')+'">';
    randomSaveButton.style.display = 'block';
  });

});

*/

kv.share = function() {

  if (navigator.canShare) {
    navigator.share({
    title: 'Kiloverse',
    text: 'Join me in the kiloverse - plot  ',
    url: kv.addressFromIndex(kv.kilo.tokenId)
  }).then(() => console.log('Share was successful.'))
  .catch((error) => console.log('Sharing failed', error));
  }

}




function clearMintForm() {
  mintFile.value = '';
  displayFile.value = '';

  if(!/safari/i.test(navigator.userAgent)){
    mintFile.type = ''
    mintFile.type = 'file'
    displayFile.type = ''
    displayFile.type = 'file'
  }

  [artName, artDescription, artTags, artEditions, artRoyalties].forEach((f, i) => {
    f.value = '';
  });

}

kv.makeCloudflareLink = function(ipfs) {
  if(!ipfs)debugger;

  if(!ipfs.includes('ipfs'))return ipfs;
  return 'https://'+kv.gateway()+'/ipfs/'+ipfs.replace('ipfs://','');

}


kv.searchAS = function (page) {

  asstuff.innerHTML = '';
  page = page || 1;
  var query = asSearch.value;
  var type = artSearchType.value;


  kv.api('searchHen', 'POST', {query:query, type:type}, function(r){

    r.forEach((art, i) => {
      var thumb = document.createElement('div');
      var art = {tokenId:art.tokenId, contract:art.contract.address, ...art.metadata};
      var ipfs = art.displayUri  || art.artifactUri;
      var url = '';
      if(ipfs)url = kv.makeCloudflareLink(ipfs);
      //console.log('thumb: '+url);
      thumb.style.backgroundImage = 'url('+url+')';
      thumb.style.backgroundSize = 'contain';
      thumb.style.width = '100px';
      thumb.style.height = '56px';
      thumb.style.float = 'left';
      asstuff.appendChild(thumb);
      var best = 0;
      var bg;
      /*
      vid.video_files.forEach((v, i) => {
        if((v.height > best) && (v.height <= 720)){
          best = v.height;
          bg = v;
          bg.image = vid.image;
        }
      });
      */
      thumb.onpointerup = function() {
        //kv.kilo.backgroundUri = bg.link;
      //  kv.kilo.backgroundLink = bg;
      //fuck

                if (confirm('Add  - ' + (art.name || '') + '?') == true) {
                  kv.addToKilo(art);
                  kv.saveAndRenderKilo();
                }

      }

    });
            if(r.page){
              var nb = document.createElement('div');
              nb.innerHTML = 'NEXT';
              nb.onpointerup = function() {
                kv.searchBG(r.page+1);
              }
              bgstuff.append(nb);
            };
  });
}

kv.searchBG = function (page) {

    bgstuff.innerHTML = '';
    page = page || 1;
    var query = bgSearch.value;
    kv.api('pexels/'+ encodeURIComponent(query) +'/80/'+page, 'GET', {}, function(r){
      r.videos.forEach((vid, i) => {
        var thumb = document.createElement('div');
        thumb.style.backgroundImage = 'url('+vid.image+')';
        thumb.style.backgroundSize = 'contain';
        thumb.style.width = '100px';
        thumb.style.height = '56px';
        thumb.style.float = 'left';
        bgstuff.appendChild(thumb);
        var best = 0;
        var bg;
        vid.video_files.forEach((v, i) => {
          if((v.height > best) && (v.height <= 720)){
            best = v.height;
            bg = v;
            bg.image = vid.image;
          }
        });

        thumb.onpointerup = function() {
          kv.kilo.backgroundUri = bg.link;
          kv.kilo.backgroundLink = bg;
          kv.updateBackground();
          kv.saveAndRenderKilo();
        }
      });
              if(r.page){
                var nb = document.createElement('div');
                nb.innerHTML = 'NEXT';
                nb.onpointerup = function() {
                  kv.searchBG(r.page+1); }
                bgstuff.append(nb);
              };
    });
}

kv.launchAS = function () {

  launchModal('as');

}

kv.createUploader = function() {


}

kv.createInput = function() {

}

/*
kv.launchBG = function () {

  bgstuff.innerHTML = '';

  launchModal('bg');
  addActions([
    {id:'modalSaveButton', text:'DONE', dontDismiss:false, callback:function(){
    }}
  ]);
}
*/

kv.checkForWallet = function() {
  if(!kv.activeAccount){
    alert("Connect a wallet to mint.")
    return false;
  }
  return true;
}

kv.mintChoices = [
  {type:'land'},
  {type:'art'},
  {type:'music'},
  {type:'video'},
  {type:'kilowear'},
  {type:'drinks'}
];

kv.launchMintArt = function() {


  var text = ''
  var style = ['image', 'audio', 'video'];
  for(const property in kv.mimeTypes){
    style.forEach(s=> {
      var pre = s+'/';
      var val = kv.mimeTypes[property];
      if(val.includes(pre)){
        text += val.replace(pre, '');
        text +=', '
      }
    });
  };

  mintChoices.innerHTML = '';
  kv.mintChoices.forEach((c, i) => {
      const div = document.createElement('div');
      div.innerHTML = c.type;
      div.className = 'col-2'
      div.style.backgroundColor = '#111';
      mintChoices.appendChild(div);
      div.onpointerup = function() {
        alert(c.type);
      }
  });

  kv.showHide(whatMint, false);



  launchModal('mintArt');

}

kv.mintButtonPressed = function() {

  if(!kv.checkForWallet())return;
  kv.submitMintForm();

}

kv.mintPlotButtonPressed = function() {


      if(propertyCount.value.length > 2) {


        //KT1TpRsnNWswaWFxdFZTTWRgh4cnijjxYqdS
        kv.api('mintkiloplots', 'POST',  {plots:propertyCount.value,  address:kv.walletAddress}, (r)=>{

          alert('operation has been submitted,  check your balance shortly...');


        });

        return;

      }

      if(parseInt(propertyCount.value.length) == 0){
        alert('please enter the number of properties to mint');
        return;
      }

      kv.showHide(plotLoading, true);
      kv.showHide(plotSales, false);
      kv.showHide(plotSuccess, false);
      kv.showHide(modalSaveButton, false);



      kv.batchmint(propertyCount.value, kv.plotPrice, ()=>{
        //spin
        kv.showHide(plotLoading, false);
        kv.showHide(plotSales, false);
        kv.showHide(plotSuccess, true);

        globalModal.hide();


      });

}

kv.launchPropertySale = function() {
  launchModal('properties');

  kv.showHide(plotLoading, false);
  kv.showHide(plotSales, true);
  kv.showHide(plotSuccess, false);



  kv.api('mintfees', 'GET', {}, (r)=>{

    //debugger;
    r.forEach((c, i) => {
      if(c.key == kv.propertyContractAddress){
        kv.plotPrice = c.value/1000000;
        floorPrice.innerHTML = kv.plotPrice;
      }
    });

  });


}




kv.launchKilobots = function() {

  launchModal('kilobot');
  kilobotContainer.innerHTML = '';
  kv.api('users','POST', {bots:true}, (r)=>{
    //debugger;
    r.users.forEach((user, i) => {
      const pd = kv.addUser(user);
      kilobotContainer.appendChild(pd);
      pd.addEventListener('pointerup', ()=>{
        kv.launchUserProfile(user);
      });
    });

    const b = document.createElement('button');
    kilobotContainer.appendChild(b);
    b.className = 'btn round btn-green-moon w-100 mt-3'
    b.innerHTML = 'Create Bot';
    b.addEventListener('pointerup', ()=>{
      var bot =  {};//TODO SET DEFAULTS...?
      bot.isBot = true;
      kv.userBeingViewed = bot;
      kv.launchUserSettings(bot, false);
    //  kv.launchUserProfile(user);
    });

  });

}






kv.cancelModal = function() {
    globalModal.hide();
}



kv.userButtonPressed = function() {
  if(!kv.user){
    alert('could not find user');
    return;
  }
  kv.closePromo();
  kv.launchUserProfile(kv.user);
}

kv.makeComposite = function(anyuser, div, params) {

  div.innerHTML = '';
  var iconAvatar = document.createElement('div');
  iconAvatar.className = 'iconAvatar';
  div.appendChild(iconAvatar);

  var iconKilowear = document.createElement('div');
  div.appendChild(iconKilowear);
  iconKilowear.className = 'iconKilowear';

  var iconDrink = document.createElement('div');
  iconDrink.className = 'iconDrink';
  div.appendChild(iconDrink);


  if(!params){
    params = {av:(anyuser.avatar && anyuser.avatar.id) || false, kw:anyuser.kilowear.id, dr:anyuser.drink.id, scale:(anyuser.avatar && anyuser.avatar.scale) || 1};
  }


  if(params.av){
      iconAvatar.style.backgroundImage = 'url(https://cdn.kiloverse.io/'+params.av+'.png)';
      iconAvatar.style.backgroundSize = (params.scale) ? ((params.scale*100) + '%') : '100%';
  } else {
      iconAvatar.style.backgroundImage = 'none';
  }


  if(params.kw)iconKilowear.style.backgroundImage = 'url(https://cdn.kiloverse.io/'+params.kw+'.png)';
  if(params.dr)iconDrink.style.backgroundImage = 'url(https://cdn.kiloverse.io/'+params.dr+'.png)';

  div.classList.remove('selfie');


}

kv.addUser = function(user) {

  const pd =document.createElement('div');
  pd.style.width = '100px';
  pd.style.height = '120px';
  pd.style.position = 'relative';
  pd.style.float = 'left';

  const d = document.createElement('div');
  d.style.width = '100px';
  d.style.height = '100px';
  d.style.position = 'relative';

  const nametag = document.createElement('div');
  nametag.innerHTML = user.name;
  nametag.style = 'width:100px;font-size:11px;text-align:center;color:white;position:absolute;bottom:3px;';
  pd.appendChild(nametag);
  pd.appendChild(d);
  kv.makeComposite(user, d);
  return pd;
}


kv.showHide = function(el, show, display) {

  if(!el){
    debugger;
  }
  if(show){
    el.classList.remove('d-none');
    if(display)el.classList.add(display)
  }else{
    el.classList.add('d-none');
  }
}

kv.crazyTransfer = function() {

    kv.api('transfer', 'POST', {url:kv.kilo.backgroundLink.link, tokenId:kv.kilo.tokenId}, function(r){

    });
}

kv.transferPexels = function(url, tokenId) {

  kv.api('transfer', 'POST', {url:url, tokenId:tokenId}, function(r){
    debugger;
  });

}


kv.submitFile = function(f, params) {

  return new Promise((resolve, reject) => {

  //var f = genericFile.files[0];
  var xhr = new XMLHttpRequest();
  xhr.open('POST', 'https://api.kiloverse.io/upload', true);
  xhr.onload = function (r) {
    if (xhr.status == 200) {


      const itemId = JSON.parse(xhr.response).itemId;
      resolve(itemId);

    }
  };
  var meta = params;
  var formData = new FormData();

  //meta.type = type;
  //meta.strength = strength;
  meta.userId = kv.user.id;
  formData.append('metadata', JSON.stringify(meta));
  formData.append('form-files', f);
  xhr.send(formData);

  });

}



kv.submitBGUpload = function() {

  var f = genericFile.files[0];
  var xhr = new XMLHttpRequest();
  xhr.open('POST', 'https://api.kiloverse.io/upload', true);
  xhr.onload = function (r) {
    if (xhr.status == 200) {
      var backgroundId = JSON.parse(xhr.response).itemId;
      alert('backgroundId');
      kv.kilo.backgroundId = backgroundId;
      kv.updateBackground();
      kv.saveAndRenderKilo();


    }
  };
  var meta = {};
  var formData = new FormData();
  meta.type = 'background';
  meta.userId = kv.user.id;
  meta.name = genericTitle.value;
  formData.append('metadata', JSON.stringify(meta));
  formData.append('form-files', f);
  xhr.send(formData);

}


var  backgroundId;
kv.submitBGUpload = function() {

  var f = genericFile.files[0];
  var xhr = new XMLHttpRequest();
  xhr.open('POST', 'https://api.kiloverse.io/upload', true);
  xhr.onload = function (r) {
    if (xhr.status == 200) {
      var backgroundId = JSON.parse(xhr.response).itemId;
      alert('backgroundId');
      kv.kilo.backgroundId = backgroundId;
      kv.updateBackground();
      kv.saveAndRenderKilo();


    }
  };
  var meta = {};
  var formData = new FormData();
  meta.type = 'background';
  meta.userId = kv.user.id;
  meta.name = genericTitle.value;
  formData.append('metadata', JSON.stringify(meta));
  formData.append('form-files', f);
  xhr.send(formData);

}


  kv.submitMintForm = function() {

          if(!kv.kilo){
            alert('kilo missing');
            return;
          }

          if(!kv.user){
            alert('user missing');
            return;
          }

          var files = mintFile.files;
        //  var file = files[0];

          var displayfiles = displayFile.files;
        //  var displayfile = displayFile[0];

          if(artName.value.length == 0){
            alert('Please enter name');
            return;
          }

          if(artEditions.value.length == 0){
            alert('please enter number of editions');
            return;
          }

          mintArtForm.classList.add('d-none');
          mintStatus.classList.remove("d-none");


          var tags = [];

          artTags.value.split(',').forEach((tag, i) => {
            const clean = tag.trim();
            if(clean.length>0)tags.push(clean);
          });

          var mt = 'unknown';
          //debugger;
          var fn = mintFile.files[0].name;
          var ext = fn.split(".").pop();
          for(const name in kv.mimeTypes){
            if(kv.mimeTypes[name].includes(ext)){
              mt = kv.mimeTypes[name];
            }
          }

          var meta = {
            userId:kv.user.id,
            address:kv.walletAddress,// ???
            type:'kilo', name:artName.value || '',
            description:artDescription.value || '',
            symbol:'KILO',
            decimals:0,
            editions:artEditions.value || 1,
            royalties:artRoyalties.value || 0,
            contract:kv.artContractAddress,
            tags:tags,
            formats:[mt]
          }


          var xhr = new XMLHttpRequest();

          xhr.open('POST', 'https://api.kiloverse.io/upload', true);
          xhr.onload = function (r) {
            if (xhr.status == 200) {
              const item = JSON.parse(xhr.response);
              kv.mint(item, (ok)=>{
                mintArtForm.classList.remove('d-none');
                mintStatus.classList.add("d-none");
                if(ok){
                  alert('Minting successful. Item will be available shortly in your profile under "minted".');
                }

                clearMintForm();
              });
            }else{
              //debugger;
            }
          };

          var formData = new FormData();
          meta.type = artType.value;
          formData.append('metadata', JSON.stringify(meta));
          formData.append('form-files', mintFile.files[0]);
          formData.append('form-files', displayFile.files[0]);
          mintStatus.innerHTML = 'submitting...';
          xhr.send(formData);

  };



  kv.api2 = function(endpoint, type, params) {

    return new Promise((resolve, reject) => {
      kv.api(endpoint, type, params, (r)=>{
        resolve(r);
      });
    });

  }


  kv.api = function(endpoint, type, params, cb) {
    const url = 'https://api.kiloverse.io/'+endpoint;
    console.log('api calling:'+url);
    const request = new XMLHttpRequest();
    request.open(type, url, true);
    request.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
    request.onload = function() {

      if (request.status === 200) {
        var json = JSON.parse(request.responseText);
        if(cb)cb(json);
      }
    };
    var formData = {};
    formData.data = params;
    request.send(JSON.stringify(formData));
  }
